











import { Component, Vue } from 'vue-property-decorator'

import MedicalFormQuestionForm from '@/partials/forms/Medical/Form/Question/form.vue'

@Component({
  components: {
    MedicalFormQuestionForm
  }
})
export default class MedicalFormsQuestionEdit extends Vue {

}
